<template>
  <div class="center mt-4" v-if="loading">
    <v-progress-circular indeterminate color="primary" size="60"></v-progress-circular>
  </div>

  <div v-else-if="found === false">
    <div class="wait mt-4">
      <h2>No record was found.</h2>
      <v-icon size="80" color="primary"> mdi-close-octagon-outline </v-icon>
    </div>
  </div>

  <div class="payment" v-else-if="data.id">
    <div class="payment-general">
      <div class="line-row">
        <v-icon>{{ icons.mdiCashMultiple }}</v-icon>
        <span class="text-payment">Payment</span>
      </div>
      <div class="line-row header">
        <span class="amount"
          ><span class="total">${{ data.total_amount }}</span> USD</span
        >
        <span v-if="data.status" :class="data.status == 'succeeded' ? 'succeeded' : 'failed'"
          >{{ data.status }}<v-icon class="icon">{{ icons.mdiCheck }}</v-icon></span
        >
      </div>
      <v-divider />
      <div class="payment-row">
        <div class="item">
          <span class="label">Date</span>
          <span class="value">{{ formatDate(data.created_at) }}</span>
        </div>
        <div class="divider-vertical" v-if="data.user"></div>
        <div class="item" v-if="data.user">
          <span class="label">Customer</span>
          <a class="email" :href="'emailto:' + data.user ? data.user.email : ''"
            ><span>{{ data.user ? data.user.email : '' }}</span></a
          >
        </div>
        <div class="divider-vertical"></div>
        <div class="item">
          <span class="label">Payment Method</span>
          <span class="value">{{ data.payment_method }}</span>
        </div>
      </div>
    </div>

    <div class="payment-detail">
      <div class="line-row header">
        <h2 class="p-title">Payment Deatils</h2>
      </div>
      <v-divider />
      <div class="content">
        <span class="label">transaction Id: </span>
        <span class="value">{{ data.transaction_id }}</span>

        <span class="label">Amount: </span>
        <span class="value">${{ Number(data.total_amount).toFixed(2) }}</span>

        <span class="label">Fee: </span>
        <span class="value">${{ Number(data.fee ? data.fee : 0).toFixed(2) }}</span>

        <span class="label">Payment Method: </span>
        <span class="value">{{ data.payment_method }}</span>

        <span class="label">Card Number: </span>
        <span class="value">{{ data.hidden_card_number }}</span>

        <span class="label">Status: </span>
        <span class="value">{{ data.status }}</span>

        <span class="label">Description: </span>
        <span class="value">{{ data.message }}</span>
      </div>
    </div>

    <div class="payment-detail">
      <div class="line-row header">
        <h2 class="p-title">Items</h2>
      </div>
      <v-divider />
      <div class="item-row" v-for="item in data.details" :key="item.id">
        <span>{{ item.service.description }}</span>
        <span>${{ item.amount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { mdiCashMultiple, mdiCheck } from '@mdi/js'

export default {
  data() {
    return {
      loading: true,
      data: {},
      found: false,
      errors: null,
      icons: {
        mdiCashMultiple,
        mdiCheck,
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions({
      getDetail: 'payment/getDetail',
    }),
    getData() {
      this.getDetail(
        new URLSearchParams({
          ...this.$route.params,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data && resp.data.id) {
              this.found = true
              this.data = resp.data
            } else {
              this.found = false
            }
          }
        })
        .catch(error => {
          console.log(error)

          this.found = false
          this.setSnackbar({
            status: true,
            text: 'Failed to get data.',
            color: 'error',
          })

          if (error.response.data && error.response.data.status === 'error') {
            this.errors = error.response.data.errors
            this.found = false
          } else {
            this.errors = null
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDate(date) {
      return moment(date).format('lll')
    },
  },
}
</script>

<style lang="scss" scoped>
.payment {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.wait {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.line-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.text-payment {
  font-size: 12;
  font-weight: 600;
  text-transform: uppercase;
}
.payment-row {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
}
.payment-row .item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}
.divider-vertical {
  height: 45px;
  width: 1px;
}
.amount {
  margin-top: 10px;
  font-size: 30px;
  margin-bottom: 15px;
}
.amount .total {
  font-weight: 800;
}
.succeeded {
  text-transform: capitalize;
  background: #cbf4ca;
  border-radius: 10px;
  padding: 1px 15px;
  color: #0e6245;
}
.succeeded .icon {
  color: #0e6245;
}
.failed {
  text-transform: capitalize;
  background: #f8d7da;
  border-radius: 10px;
  padding: 1px 15px;
  color: #721c24;
}
.failed .icon {
  color: #721c24;
}
.email {
  text-decoration: none;
}

.payment-detail {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.payment-detail .content {
  display: grid;
  grid-template-columns: repeat(2, 200px);
  row-gap: 10px;
}
.item-row {
  display: grid;
  grid-template-columns: repeat(2, 200px);
  row-gap: 10px;
}

.v-application.theme--dark {
  .divider-vertical {
    background: rgba(231, 227, 252, 0.5);
  }
}
.v-application.theme--light {
  .amount {
    color: #4f566b;
  }
  .total,
  .p-title {
    color: #1b1f36;
  }
  .divider-vertical {
    background: rgba(68, 68, 68, 0.14);
  }
  .email {
    color: #5369d4;
  }
  .label {
    color: #9fa5b1;
  }
  .value {
    color: #3c4257;
  }
}

@media (max-width: 575px) {
  .line-row {
    flex-direction: column;
  }
  .line-row.header {
    margin-bottom: 20px;
  }
  .payment-row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .payment-row .item {
    justify-content: center;
    align-items: center;
  }
  .divider-vertical {
    display: none;
    height: 1px;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .payment-detail .content,
  .item-row {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
